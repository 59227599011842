import axios from 'axios'
import {network} from '@/config'
import sth from 'sthjs';
import {entropyToMnemonic, validateMnemonic} from 'bip39';
const cryptoRandomString = require('crypto-random-string');

class Account {
  async getSthBalance(address) {
    let result = 0;
    if (!address) {
      return result;
    }
    try {
      result = (await axios.get('https://' + network.NODE + '/api/accounts/getBalance?address=' + address)).data.balance;
    } catch(e) {
      //console.log(e)
    }
    return (Math.floor(((result / 10 ** 8) * 100) )/ 100)
  }

  async getPlayer(options) {
    let result = null;
    try {
      result = (await axios.post(network.API + '/api/player/get', options)).data
    } catch(e) {

    }
    return result;
  }

  async getPlayerInfo(userId) {
    let result = null;
    try {
      result = (await axios.post(network.API + '/api/player/get-info', {userId: userId})).data
    } catch(e) {

    }
    return result;
  }

  async accountCreate(pin) {
    const wif = cryptoRandomString(32);
    const mnemonic = entropyToMnemonic(wif);
    const PUB_KEY = sth.crypto.getKeys(mnemonic).publicKey;
    return {
      userId: sth.crypto.getAddress(PUB_KEY),
      secret: mnemonic,
      pubKey: PUB_KEY,
      wif: wif,
      pin: pin
    }
  }

  async accountGenerate() {
    const wif = cryptoRandomString(32);
    const mnemonic = entropyToMnemonic(wif);
    const PUB_KEY = sth.crypto.getKeys(mnemonic).publicKey;
    const userId = sth.crypto.getAddress(PUB_KEY);
    const gameChain = await this.accountImportChain({
      secret: mnemonic,
      version: 28
    });
    return {
      userId: userId,
      secret: mnemonic,
      pubKey: PUB_KEY,
      wif: wif,
      pin: null,
      gameChain: gameChain
    }
  }

  async accountImport(key) {
    const mnemonic = key;
    const PUB_KEY = sth.crypto.getKeys(mnemonic).publicKey;
    const userId = sth.crypto.getAddress(PUB_KEY);
    const gameChain = await this.accountImportChain({
      secret: key,
      version: 28
    });
    return {
      userId: userId,
      secret: mnemonic,
      pubKey: PUB_KEY,
      wif: null,
      pin: null,
      isBip39: validateMnemonic(key),
      gameChain: gameChain,
    }
  }

  async accountImportChain(options) {
    const PUB_KEY = sth.crypto.getKeys(options.secret).publicKey;
    return {
      userId: sth.crypto.getAddress(PUB_KEY, options.version),
      pubKey: PUB_KEY,
    }
  }

  async setPlayerSettings(options) {
    let result = null;
    try {
      result = (await axios.post(network.API + '/api/player/set-settings', options)).data
    } catch(e) {

    }
    return result;
  }
}

export default new Account()
