/**
 * main mixins
 **/
import {coins} from '@/config'

let open;
if (process.env.IS_ELECTRON) {
//  open = require('open');
}

import moment from 'moment';
import NumberFormat from 'number-format.js';
import copy from 'clipboard-copy';
import eventBus from '@/plugins/event-bus';

export default {
  data() {
    return {
      coins,
      mixVal: {
        copied: 'Copy to clipboard'
      },
    }
  },
  computed: {},
  methods: {
    numberFormat(numValue, mask = '# ##0.00') {
      return NumberFormat(mask, numValue)
    },
    timeFormat(tm, format = "MM/DD/YYYY") {
      return moment.unix(tm).format(format);
    },
    async pubKey() {
      return (this.$store.getters['account/account']).pubKey || null
    },
    clipboardSuccess() {
      this.mixVal.copied = 'Copied to clipboard';
      setTimeout(() => (this.mixVal.copied = 'Copy to clipboard'), 1500);
      //console.log('success', value.text)
    },
    async copyText(text) {
      copy(text);
      this.clipboardSuccess()
    },

    async signMessage(options) {
      //{msg, address, pin}
      let secret = await this.addressDecrypt(options.address, options.pin);
      let data = null;
      if (options.msg.length > 0 && secret) {
        data = {
          msg: options.msg,
          address: options.address,
          pubKey: await this.pubKey(),
          signature: (await this.$store.dispatch('wallet/SignMsg', {
            secret: secret,
            msg: options.msg,
          })).signature || null,
        }
      }
      if (!secret) {
        this.notify('bottom', 'right', 'secret key is not valid', 'danger')
      }
      return data
    },
    notify(verticalAlign, horizontalAlign, msg = 'Success', type = 'success') {
      this.$notify({
        message: msg,
        timeout: 5000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: type
      });
    },
    openLink(url, type = '_blank') {
      if (url) {
        if (process.env.IS_ELECTRON) {
          const {shell} = require('electron')
          shell.openExternal(url)
        } else {
          window.open(url, type);
          //window.open(url, '_blank', "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes");
        }
      }
    },
  },
}
