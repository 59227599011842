import axios from 'axios';
import packageJson from '../../package.json';

class App {
  async currentVersion () {
    return packageJson.version
  }
  async getLatestVersion() {
    let result = null;
    try {
      result = (await axios.get('https://download.playpoker.pro/version.json')).data.version;
    } catch(e) {

    }
    return result;
  }
}

export default new App();
