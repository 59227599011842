import axios from 'axios';
import {network} from '@/config';


class Games {
  /** NEW **/
  async getConfig() {
    let result = null;
    try {
      result = (await axios.get(network.API + '/api/config')).data
    } catch (e) {

    }
    return result;
  }

  async lobbyFilter(filter) {
    let result = null;
    try {
      result = (await axios.post(network.API + '/api/lobby-filter', filter)).data
    } catch (e) {

    }
    return result;
  }

  async getLobby() {
    let result = null;
    try {
      result = (await axios.get(network.API + '/api/lobby')).data
    } catch (e) {

    }
    return result;
  }

  async tableView(idx) {
    let result = null;
    try {
      result = (await axios.get(network.API + '/api/table-view/' + idx)).data
    } catch (e) {

    }
    return result;
  }

  async tableSit(options) {
    let result = null;
    try {
      result = (await axios.post(network.API + '/api/table-sit', options)).data
    } catch (e) {

    }
    return result;
  }

  async tableLogout(options) {
    let result = null;
    try {
      result = (await axios.post(network.API + '/api/table-logout', options)).data
    } catch (e) {

    }
    return result;
  }


  async playerAction(options) {
    let result = null;
    try {
      result = (await axios.post(network.API + '/api/player/action', options)).data
    } catch (e) {

    }
    return result;
  }

  async getFreeChips(options) {
    let result = null;
    try {
      result = (await axios.post(network.API + '/api/chips/get/free', options)).data
    } catch (e) {

    }
    return result;
  }

  async logoutAll(options) {
    let result = null;
    try {
      result = (await axios.post(network.API + '/api/logout-all', options)).data
    } catch (e) {

    }
    return result;
  }

}

export default new Games()
