exports.colors = {
  default: '#344675',
  primary: '#42b883',
  info: '#1d8cf8',
  danger: '#fd5d93',
  orange: '#ff8a76',
  teal: '#00d6b4',
  cosmos: '#3baac2',
  primaryGradient: [
    'rgba(76, 211, 150, 0.1)',
    'rgba(53, 183, 125, 0)',
    'rgba(119,52,169,0)'
  ],
  purpleGradient: ['rgba(253,93,147,0.8)', 'rgba(253,93,147,0)']
};

exports.network = {
  //WS: 'ws://192.168.1.55:22555',
  //API: 'http://192.168.1.55:22666',

  WS: 'wss://api.playpoker.pro/ws',
  API: 'https://api.playpoker.pro',

  //WS: 'ws://78.47.78.79:22555',
  //API: 'http://78.47.78.79:22666',
  EXPLORER: 'https://blockexplorer.smartholdem.io/#/',
  AGENT: 'sth-games',
  NODE: 'node0.smartholdem.io',
  PEERS: [
    'node0.smartholdem.io',
    'node1.smartholdem.io',
    'node2.smartholdem.io',
    'node3.smartholdem.io',
  ]
};

exports.gamechain = {
  NODE: 'https://node.playpoker.pro',
  BANK: 'CVEafWTaFYMMbnUmbk5eMfmzYaHfqczapV'
};

exports.bonus = {
  days: [
    1000,
    3000,
    5000,
    6000,
    7000,
    8000,
    9000,
    10000,
    11000,
    12000,
  ]
};

exports.exchange = {
  API: 'https://ex.smartholdem.io'
};

exports.err = {
  '001': ''
};

