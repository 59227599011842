import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import localforage from 'localforage';
import {pullAll, keys} from 'lodash';

import vuexPersistReady from '@/store/plugins/vuex-persist-ready'

import app from '@/store/modules/app'
import account from '@/store/modules/account'
import wallet from '@/store/modules/wallet'
import network from '@/store/modules/network'
import games from '@/store/modules/games';

Vue.use(Vuex);

const modules = {
  app: app,
  account: account,
  wallet: wallet,
  network: network,
  games: games,
};

// Modules that should not be persisted
const ignoreModules = [];

const vuexPersist = new VuexPersistence({
  // It is necessary to enable the strict mode to watch to mutations, such as `RESTORE_MUTATION`
  strictMode: true,
  asyncStorage: true,
  key: 'sth-poker',
  storage: localforage,
  modules: pullAll(keys(modules), ignoreModules)
});

export default new Vuex.Store({
  state: {
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
    }
  },
  modules,
  strict: process.env.NODE_ENV !== 'production',
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
  },
  actions: {
    resetData() {
      return localforage.clear()
    },
  },
  plugins: [
    vuexPersist.plugin,
    vuexPersistReady
  ]
})
