/*!
 =========================================================
 * SmartHoldem Card Engine UI
 =========================================================
 * Coded by TechnoL0g
 =========================================================
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import eventBus from '@/plugins/event-bus';
import synchronizer from './plugins/synchronizer';
import DashboardPlugin from './plugins/dashboard-plugin';

// router setup
import router from './routes/router';
import i18n from './i18n';
import mixins from './mixins'
import './registerServiceWorker'
import store from './store'
import App from './App.vue';

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(synchronizer);

Vue.config.productionTip = false;
Vue.prototype.$eventBus = eventBus;
Vue.mixin(mixins);


/* eslint-disable no-new */
new Vue({
 el: '#app',
 render: h => h(App),
 router,
 store,
 i18n
});
