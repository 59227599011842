<template>
  <router-view class="cursor"></router-view>
</template>

<script>
import eventBus from '@/plugins/event-bus';

export default {
    methods: {
        initializeLayout() {
            if (!this.$rtl.isRTL) {
                // Just make sure rtl css is off when we are not on rtl
                this.$rtl.disableRTL();
            }
        },
        async appClose() {
            if (process.env.IS_ELECTRON) {
                const electron = require('electron');
                let win = electron.remote.getCurrentWindow();
                win.close();
            }

            if (process.platform === 'browser') {
                window.open('','_self').close()
            }

        }
    },

    async mounted() {
        this.initializeLayout();
        this.$root.isMobile = window.innerWidth < 815;
    },

    async created() {
        const browserLang = navigator.language;
        if (browserLang === 'ru-RU') {
            this.$i18n.locale = 'ru'
        } else {
            this.$i18n.locale = 'en'
        }

        this.$store._vm.$on('vuex-persist:ready', async () => {
            const locale = await this.$store.getters['app/language'];
            if (locale) {
                this.$i18n.locale = locale;
                //console.log(this.$i18n.messages)
            } else {
                await this.$store.dispatch('app/setLanguage', this.$i18n.locale);
            }

            await this.$store.dispatch('games/logoutAll');
            await this.$store.dispatch('games/getConfig');

            this.$synchronizer.defineAll();
            this.$synchronizer.ready();
        });

        eventBus.on('player:login', async (data) => {
            this.$root.pin = data.pin;
        });

        eventBus.on('app:close', async () => {
           await this.appClose();
        });

        eventBus.on('url:open', async (url) => {
            await this.openLink(url);
        });


/*
        this.$options.sockets.onopen = (dataOpen) => {
            console.log('ws open');
            this.$socket.sendObj({op: 'get-lobby', userId: "hello"})
        }

        this.$options.sockets.onerror = (socketErr) => {
            console.log('ws error', socketErr);
        }
 */

        //this.$socket.sendObj({op: 'get-lobby', userId: "hello"})
    },
};
</script>

<style>


</style>
